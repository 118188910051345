import React, {useState} from 'react'
import ReactWOW from 'react-wow'
import {logFormSubmission} from "../../../utils/google-tag-manager";

const CompliantsForm = props => {
    const {
        isPreview,
    } = props
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    // const [company, setCompany] = useState("")
    const [mobilephone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = async (evt) => {
        if (isPreview) {
            setSubmitted(true);
            return;
        }

        evt.preventDefault();
        const data = {
            fields: [
                {
                    name: "firstname",
                    value: name,
                },
                {
                    name: "email",
                    value: email,
                },
                
                // {
                //     "name": "company",
                //     "value": company
                // },
                {
                    name: "mobilephone",
                    value: mobilephone,
                },
                {
                    name: "message",
                    value: message,
                },
            ],
            context: {
                // "pageUri": "http://localhost:8000",
                // "PageName": "Product Page Australia",
                // "skipValidation": true
            },
        };

        const response = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/25354619/b71f389d-28a5-40d4-954f-cdfc3a519ad6', {
            body: JSON.stringify(data),
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            setSubmitted(true)
            logFormSubmission('complaints-nl');
        }
    }

    return (
        <section className="complaince-area">
            <div className="container">
                <div className="">
                    <div className="text-center text-lg-start ps-0">
                        <ReactWOW delay='.1s' animation='fadeInLeft'>
                            <h1 className='display-6 pb-lg-5 px-4 px-lg-0'>Hoe werkt onze klachtenprocedure?</h1>
                        </ReactWOW>
                    </div>
                </div> 

                <div className='row'>
                    <div className="col-12 col-lg-7 pe-lg-5">
                        <div className="about-content complaints-content">
                            <div className="content">
                                <p>
                                    Wij streven ernaar om u te verstrekken van de meest hoogwaardige kwaliteit. Echter, het kan voorkomen dat u niet tevreden bent met onze manier van werken. Mocht u ontevredenheid ervaren, in welke mate dan ook, neem dan gerust contact met ons op.                                 </p>
                                <h1 className='display-6 pt-3'>Uw klacht verwerken</h1>
                                <div className="content-list">
                                    <div className='slider_list_text'>
                                        Wij zijn binnen twee werkdagen op de door u manier. Wij handelen uw klacht binnen twee weken na ontvangst van het bericht af.
                                    </div>
                                    <div className='slider_list_text'>
                                        Uw klacht wordt volgens de Regeling Gedragscode Kleinzakelijke Financiering.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-5 getintouch-form pt-4 pt-lg-0">

                    {submitted ? <h4 className='px-5 success-message-form'>Bedankt voor het indienen van uw klacht. Wij nemen deze zo spoedig mogelijk in behandeling.</h4> :
                    
                        <form onSubmit={handleSubmit}>
                            <div className="row ">
                                <div className="col-12 mx-auto">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="your_name"
                                            placeholder="Naam *"
                                            value={name}
                                            required
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                {/* <div className="col-12 mx-auto">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="company"
                                            placeholder="Bedrijfsnaam *"
                                            value={company}
                                            required
                                            onChange={e => setCompany(e.target.value)}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-12 mx-auto">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="your_email"
                                            placeholder="Email *"
                                            value={email}
                                            required
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mx-auto">
                                    <div className="form-group">
                                        <input
                                            type="tel"
                                            minLength={7}
                                            maxLength={20}
                                            className="form-control"
                                            id="your_number"
                                            placeholder="Telefoonnummer"
                                            value={mobilephone}
                                            onChange={e => setPhone(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-12 mx-auto">
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            id="your_message"
                                            placeholder="Bericht"
                                            rows="9"
                                            value={message}
                                            onChange={e => setMessage(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="text-lg-start text-center pt-1 pb-4 mx-auto">
                                <button type="submit" className="default-btn-black font-medium">
                                    VERSTUUR
                                    <span></span>
                                </button>
                            </div>
                        </form>
                        }

                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default CompliantsForm