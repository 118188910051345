import React from "react"
import ReactWOW from "react-wow"
import service2 from "../../assets/images/tp-logo.svg"

const Banner = ({ whiteTitle, greenTitle }) => {
  return (
    <div className="contact-banner">
      <div className="container">
        <div className="row align-items-center main-banner-content ">
          <div className="col-lg-12 col-md-12">
            <div className="text-white innerpage-title">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h4 className="">{whiteTitle}</h4>
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1 className="text-primary">{greenTitle}</h1>
              </ReactWOW>
            </div>
          </div>
        </div>
        <img src={service2} alt="service" className="contact-child-img" />
      </div>
    </div>
  )
}

export default Banner;